"use strict";

exports.__esModule = true;
exports.default = void 0;

require("core-js/modules/es.object.assign.js");

var _reduxForm = require("redux-form");

var _actionTypes = require("./pregnancy-resource-center/action-types");

var _actionTypes2 = require("./out-of-business/action-types");

var _actionTypes3 = require("./do-not-list/action-types");

const resourceReducer = (state = {}, action) => {
  switch (action.type) {
    case _actionTypes.GET_RESOURCE_TO_VERIFY:
      return Object.assign({}, state, action.resource);

    case _actionTypes2.OUT_OF_BUSINESS:
      return Object.assign({}, state, {
        outOfBusiness: action.outOfBusiness
      });

    case _actionTypes3.DO_NOT_LIST:
      return Object.assign({}, state, {
        doNotList: action.doNotList
      });

    case _actionTypes.CLEAR_RESOURCE:
      return {};

    default:
      return state;
  }
};

const chcResourceReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SUBMIT_CHC_FORM':
      return Object.assign({}, state, {
        chcFormData: action.chcFormData
      });

    case 'CLEAR_CHC_STATE':
      return Object.assign({}, state, {
        chcFormData: {}
      });

    default:
      return state;
  }
};

const localStateReducer = (state = {}, action) => {
  switch (action.type) {
    case _actionTypes.FORM_SUCCESSFULLY_SUBMITTED:
      return Object.assign({}, state, {
        verificationPortalFormStatus: action.formStatus
      });

    default:
      return state;
  }
};

var _default = {
  localState: localStateReducer,
  chcResource: chcResourceReducer,
  resource: resourceReducer,
  form: _reduxForm.reducer
};
exports.default = _default;