"use strict";

module.exports = [{
  nameOfResource: 'LoveLine',
  phoneNumber: '888-550-1588',
  additionalInfo: 'call or text 24/7/365',
  website: 'https://loveline.com'
}, {
  nameOfResource: 'National Sexual Assault Hotline / Live Chat',
  phoneNumber: '888-656-4673',
  additionalInfo: 'live chat also available online',
  website: 'https://hotline.rainn.org/online'
}, {
  nameOfResource: 'National Domestic Violence Hotline / Live Chat',
  phoneNumber: '888-799-7233',
  additionalInfo: 'live chat also available online',
  website: 'https://thehotline.org'
}, {
  nameOfResource: 'National Human Trafficking Hotline',
  phoneNumber: '888-373-7888',
  additionalInfo: 'live chat available online or send a text to 233733',
  website: 'https://traffickingresourcecenter.org'
}, {
  nameOfResource: 'National Suicide Prevention Lifeline',
  phoneNumber: '800-273-8255',
  additionalInfo: 'live chat also available online',
  website: 'https://suicidepreventionlifeline.org/chat'
}, {
  nameOfResource: '24-Hour National Pregnancy Help Hotline',
  phoneNumber: '800-712-4357',
  additionalInfo: "live chat available online or text 'HELPLINE' to 313131",
  website: 'https://optionline.org'
}, {
  nameOfResource: 'Substance Abuse and Mental Health (SAMHSA) National Helpline',
  phoneNumber: '800-662-4357',
  additionalInfo: '',
  website: 'https://www.samhsa.gov/find-help/national-helpline'
}];