"use strict";

exports.__esModule = true;
exports.createLoginAction = createLoginAction;
exports.createLogoutAction = createLogoutAction;
exports.logout = exports.login = exports.getInitialAppData = void 0;

require("core-js/modules/es.promise.js");

var _actionTypes = require("./action-types");

var _actionCreators = require("./verification-portal/authentication/action-creators");

async function getInitialData() {
  const response = await fetch('/api/initial-data', {
    method: 'GET'
  });
  return await response.json();
}

function createLoginAction(userDisplayName) {
  return {
    type: _actionTypes.UPDATE_LOGIN_STATE,
    isLoggedIn: true,
    userDisplayName
  };
}

function createLogoutAction() {
  return {
    type: _actionTypes.UPDATE_LOGIN_STATE,
    isLoggedIn: false
  };
}

const getInitialAppData = () => {
  return function (dispatch) {
    return getInitialData().then(initialData => dispatch({
      type: _actionTypes.GET_INITIAL_DATA,
      initialData
    }));
  };
};

exports.getInitialAppData = getInitialAppData;

const login = accessToken => {
  return function (dispatch) {
    return (0, _actionCreators.authenticateUser)(accessToken).then(result => {
      if (result) {
        dispatch(createLoginAction());
      }
    });
  };
};

exports.login = login;

const logout = () => {
  return function (dispatch) {
    return (0, _actionCreators.logoutUser)().then(result => {
      if (result) {
        dispatch(createLogoutAction());
      }
    });
  };
};

exports.logout = logout;