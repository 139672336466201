"use strict";

exports.__esModule = true;
exports.default = void 0;

require("core-js/modules/es.object.assign.js");

var _lodash = _interopRequireDefault(require("lodash"));

var _redux = require("redux");

var _actionTypes = require("./action-types");

var _reducers = _interopRequireDefault(require("./verification-portal/reducers"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const reducer = (0, _redux.combineReducers)(Object.assign({
  initialData: (state = {}, action) => {
    switch (action.type) {
      case _actionTypes.GET_INITIAL_DATA:
        return _lodash.default.assign({}, state, action.initialData);

      case _actionTypes.UPDATE_LOGIN_STATE:
        return _lodash.default.assign({}, state, {
          isLoggedIn: action.isLoggedIn,
          userDisplayName: action.userDisplayName
        });

      default:
        return state;
    }
  }
}, _reducers.default));
var _default = reducer;
exports.default = _default;