"use strict";

exports.__esModule = true;
exports.ScrollToTop = void 0;

var _react = require("react");

const ScrollToTop = () => {
  (0, _react.useEffect)(() => {
    try {
      window.scroll({
        top: 0,
        left: 0
      });
    } catch (error) {
      // Fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);
  return null;
};

exports.ScrollToTop = ScrollToTop;