// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/GT-Walsheim-Light.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/GT-Walsheim-Regular.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/GT-Walsheim-Medium.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/GT-Walsheim-Bold.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'hah-light';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-regular';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-medium';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-bold';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://./client/styles/hah-font.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,kBAAkB;CAClB,mBAAmB;CACnB,2DAAiE;AAClE;;AAEA;CACC,0BAA0B;CAC1B,kBAAkB;CAClB,mBAAmB;CACnB,2DAAmE;AACpE;;AAEA;CACC,yBAAyB;CACzB,kBAAkB;CAClB,mBAAmB;CACnB,2DAAkE;AACnE;;AAEA;CACC,uBAAuB;CACvB,kBAAkB;CAClB,mBAAmB;CACnB,2DAAgE;AACjE","sourcesContent":["@font-face {\n\tfont-family: 'hah-light';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/GT-Walsheim-Light.woff') format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-regular';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/GT-Walsheim-Regular.woff') format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-medium';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/GT-Walsheim-Medium.woff') format('woff');\n}\n\n@font-face {\n\tfont-family: 'hah-bold';\n\tfont-style: normal;\n\tfont-weight: normal;\n\tsrc: url('../assets/fonts/GT-Walsheim-Bold.woff') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
